<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="selected"
    :filter="customFilter"
    outlined
    :items="employees ? employees : []"
    :label="label ? label : $trans('employees')"
    :item-text="$helpers.getEmployeeName"
    hide-details="auto"
    :placeholder="$trans('autocomplete_hint')"
    :dense="dense"
    :rules="rules"
    :loading="loading"
    :disabled="disabled"
    :no-data-text="$trans('nothing_found_here')"
    return-object
    clearable
    :multiple="multiple"
    :attach="'#' + uniqueId"
    class="cd-fake-outer"
    @change="onChangeHandle"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>

    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="data.index < maxItemsInSelectField">
          <template v-if="chips || smallChips">
            <v-chip
              :disabled="disabled"
              :small="smallChips"
              :close="deletableChips"
              @click:close="removeChip(data.item)"
            >
              <v-auto-complete-chips-tooltip>
                <employee-card-details :employee="data.item" />
              </v-auto-complete-chips-tooltip>
              <v-avatar :size="32" left class="mr-2">
                <v-img :src="$helpers.getAvatarSrc(data.item.user)" />
              </v-avatar>
              <span class="text-truncate">{{
                $helpers.getEmployeeName(data.item)
              }}</span>
            </v-chip>
          </template>
          <template v-else>
            <v-avatar :size="32" left class="mr-2">
              <v-img :src="$helpers.getAvatarSrc(data.item.user)" />
            </v-avatar>
            <span class="text-truncate">{{
              $helpers.getEmployeeName(data.item)
            }}</span>
          </template>
        </template>
        <div
          v-if="multiple && data.index === maxItemsInSelectField"
          class="grey--text text-caption"
        >
          (+{{ selected.length - maxItemsInSelectField }} {{ $trans("more") }})
        </div>
      </div>
    </template>

    <template #item="data">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div class="d-flex align-center pointer my-2" v-on="on">
            <v-avatar :size="32" left class="mr-2">
              <v-img :src="$helpers.getAvatarSrc(data.item.user)" />
            </v-avatar>
            <span class="text-break cd-v-select-line-height">{{
              $helpers.getEmployeeName(data.item)
            }}</span>
          </div>
        </template>
        <employee-card-details :employee="data.item" />
      </v-tooltip>
    </template>

    <template #append-outer>
      <div :id="uniqueId"></div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeCardDetails from "@/components/EmployeeCardDetails.vue";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";
import VAutoCompleteChipsTooltip from "@/lib/calendesk-js-library/components/VAutoCompleteChipsTooltip.vue";

export default {
  name: "EmployeeField",
  components: { VAutoCompleteChipsTooltip, EmployeeCardDetails },
  mixins: [sharedFieldActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    keepMenuOpenOnSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/getAllEmployees",
    }),
  },
  watch: {
    value(val) {
      this.selected = val;
    },
  },
  methods: {
    onChangeHandle(employee) {
      if (!this.keepMenuOpenOnSelect) {
        this.$refs.autocomplete.isFocused = false;
      }

      this.$emit("input", this.$helpers.cloneObject(employee));
      this.$emit("change", this.$helpers.cloneObject(employee));
    },
    customFilter(item, queryText) {
      const textId = `${item.id}`.toLowerCase();
      const textOne = item.user.name.toLowerCase();
      const textTwo = item.user.surname.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textId.indexOf(searchText) > -1 ||
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
      );
    },
    removeChip(item) {
      if (this.multiple) {
        const index = this.selected.findIndex(
          (employee) => employee.id === item.id
        );
        if (index > -1) {
          this.selected.splice(index, 1);
        }
      } else {
        this.selected = null;
      }
    },
  },
};
</script>
