<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container class="white">
      <v-row>
        <v-col class="text-h6">
          {{ $trans("schedule_name") }}: {{ schedule.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("availability_schedule_assign_to_employees_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="min-height: 400px">
          <employee-field
            v-model="selectedEmployees"
            :label="$trans('select_employees_label')"
            :disabled="isLoading"
            multiple
            chips
            deletable-chips
            keep-menu-open-on-select
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import EmployeeField from "@/views/dashboard/pages/Calendar/components/forms/Booking/EmployeeField.vue";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  components: { CalendeskInformationMessage, EmployeeField },
  mixins: [sharedActions],
  data() {
    return {
      selectedEmployees: null,
      initialSelectedEmployeeIds: null,
      isLoading: false,
      schedule: null,
    };
  },
  created() {
    if (this.getDialog.data && this.getDialog.data.employees) {
      this.schedule = this.getDialog.data;
      this.selectedEmployees = this.schedule.employees;
      this.initialSelectedEmployeeIds = this.selectedEmployees.map(
        (employee) => employee.id
      );
    }
  },
  methods: {
    ...mapActions({
      updateMultipleEmployees: "employee/updateMultiple",
      refreshSchedulesList: "availabilitySchedule/refreshSchedulesList",
    }),
    async handleSave() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoading = true;
          this.$emit("ctaButtonLoading", true);

          const { unassignEmployeeIds, assignEmployeeIds } =
            this.calculateEmployeeChanges();

          await this.unassignEmployees(unassignEmployeeIds);
          await this.assignEmployees(assignEmployeeIds);

          await this.refreshSchedulesList();
          await this.clearCache("employee-list-v2");

          successNotification("update_success");
        } catch (error) {
          errorNotification(null, error, false);
        } finally {
          this.isLoading = false;
          this.$emit("ctaButtonLoading", false);
          this.$emit("close");
        }
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },

    calculateEmployeeChanges() {
      const assignEmployeeIds = this.selectedEmployees.map(
        (employee) => employee.id
      );
      const unassignEmployeeIds = this.initialSelectedEmployeeIds.filter(
        (id) => !assignEmployeeIds.includes(id)
      );

      return { unassignEmployeeIds, assignEmployeeIds };
    },

    async unassignEmployees(employeeIds) {
      if (employeeIds && employeeIds.length > 0) {
        await this.updateMultipleEmployees({
          employee_ids: employeeIds,
          fields: {
            availability_schedule_id: null,
          },
        });
      }
    },

    async assignEmployees(employeeIds) {
      if (employeeIds && employeeIds.length > 0) {
        await this.updateMultipleEmployees({
          employee_ids: employeeIds,
          fields: {
            availability_schedule_id: this.schedule.id,
          },
        });
      }
    },
  },
};
</script>
